/* Faq 1 ---------------------------------- */ 
.faq-area-1 {
    margin-top: -400px;
    padding-top: 400px !important; 
}
.accordion-card {
    margin-bottom: 5px;
    transition: 0.4s ease-in-out;
    overflow: hidden;
    background: $smoke-color;
    border: 0;
    .accordion-button {
        font-size: 22px;
        font-weight: 500;
        font-family: $title-font;
        color: $title-color;
        border: 0;
        background-color: transparent;
        padding: 0px 0px 0px 50px;
        min-height: 70px;
        gap: 10px;
        margin-bottom: 0;
        text-align: left;
        transition: 0.3s;
        position: relative;
        box-shadow: none;
        border-bottom: 1px solid rgba(36, 63, 55, 0.2); 
       
        &:after {
            content: "\f067";
            height: 30px;
            width: 30px;
            border-radius: 5px;
            line-height: 30px;
            background: #C4F500; 
            border: 0;
            font-family: $icon-font;
            color: $theme-color;
            font-weight: 900;
            font-size: 18px;
            display: grid;
            justify-content: center;
            align-items: center;
            text-align: center;
            position: absolute;
            top: 22px;
            left: 0px;
            transition: 0.3s ease-in-out;
        }
        &:focus {
            outline: none;
            box-shadow: none;
        }
        &:not(.collapsed) {
            color: $title-color;
            &:after {
                content: '\f068';
                background-color: $theme-color;
                color: $white-color;
                transform: rotate(180deg);
            }
        }
    }
    .accordion-collapse {
        border: none;
    }
    .accordion-body {
        border-radius: 0;
        background-color: transparent;
        border: none;
        padding: 30px 30px 30px 0px;
        margin-top: -0.5em;
        
        border-bottom: 1px solid rgba(36, 63, 55, 0.2); 
        p {
            margin-bottom: -0.6em;
            font-size: 18px;
            font-weight: 400;
            font-family: $title-font;
        }
    }
    &:last-child {
        margin-bottom: 0;
    }
}
@include ml {
    .accordion-card .accordion-button {
        font-size: 20px;
    }
    .accordion-card .accordion-button:after {
        font-size: 16px;
        top: 13px;
    }
}
@include xs {
    .accordion-card .accordion-button {
        padding: 15px 25px 15px 45px;
    }
}

/* Faq 2 ---------------------------------- */ 
.accordion-card.style2 {
    background: transparent;
}

/* Faq 3 ---------------------------------- */ 
.accordion-card.style3 {
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.05);
    background: $white-color;
    padding: 10px 30px;
    &:last-child {
        margin-bottom: 0;
    }
    .accordion-button {
        border-bottom: 0;
        font-size: 22px;
        font-weight: 500;
        padding: 0 70px 0 0;
        &:after {
            left: auto;
            right: 0;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            top: 15px;
            content: "\f105";
            background: #F3F5F4;
        }
        &:not(.collapsed) {
            &:after {
                background: $theme-color;
                content: "\f107";
            }
        }
        @include xs {
            font-size: 18px;
        }
    }
    .accordion-body {
        padding: 0 0 20px;
        border-bottom: 0;
        @include sm {
            padding: 10px 0 20px;
        }
    } 
    &:has(.accordion-button:not(.collapsed)) {
        background: #EBF3EE;
    }
}

/* Faq 4 ---------------------------------- */ 
.accordion-card.style4 {
    border-bottom: 1px solid #7A7A7A;
    background: transparent;
    margin-bottom: 30px;
    padding-bottom: 30px;
    &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .accordion-button {
        display: block;
        border-bottom: 0;
        font-size: 30px;
        font-weight: 700;
        padding-top: 5px;
        padding-left: 70px;
        .event-date {
            display: block;
            font-size: 18px;
            font-weight: 400;
            margin-top: 20px;
        }
        @include sm {
            font-size: 24px;
            padding-left: 60px;
            .event-date {
                margin-top: 10px;
            }
        }
        @include xs {
            padding-bottom: 0;
        }
        &:after {
            border-radius: 50%;
            top: 0;
            width: 40px;
            height: 40px;
            background: transparent;
            border: 1px solid $theme-color;
            color: $title-color;
            content: "\f105";
        }
        &:not(.collapsed):after {
            background: $theme-color;
            color: $theme-color2;
            transform: rotate(90deg);
        }
    }
    .accordion-body {
        border-bottom: 0;
        padding: 20px 0 0 70px;
        .event-text {
            margin-bottom: 0;
        }
    }
}

/* Faq 5 ---------------------------------- */ 
.faq-thumb5 {
    overflow: hidden;
    display: inline-block;
    position: relative;
    img {
        border-radius: 20px;
    }
    .about-counter-wrap {
        background: $white-color;
        border-radius: 10px;
        padding: 30px;
        display: inline-flex;
        position: absolute;
        right: 10px;
        top: 10px;
        gap: 5px;
        align-items: center;
        .about-counter {
            margin-bottom: 0;
            color: $theme-color;
            font-size: 60px;
            font-weight: 700;
            align-items: center;
        }
        .sec-desc {
            font-size: 18px;
            font-weight: 400;
            color: $theme-color;
            max-width: 82px;
            line-height: 1.888;
            margin-bottom: 0;
        }
    }
    @include xs {
        img {
            border-radius: 10px;
        }
        .about-counter-wrap {
            position: initial;
            margin-top: 20px;
            animation: none !important;
            display: flex;
            justify-content: center;
        }
    }
}
.accordion-card.style5 {
    margin-bottom: 30px;
    border-radius: 20px;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.05);
    background: #202022;
    padding: 5px 30px;
    &:last-child {
        margin-bottom: 0;
    }
    .accordion-button {
        border-bottom: 0;
        font-size: 22px;
        font-weight: 500;
        padding: 0 70px 0 0;
        color: $white-color;
        &:after {
            left: auto;
            right: 0;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            top: 15px;
            content: "\f105";
            background: transparent;
            border: 1px solid $theme-color2;
            color: $theme-color2;
        }
        &:not(.collapsed) {
            &:after {
                background: transparent;
                border: 1px solid $white-color;
                color: $white-color;
                content: "\f107";
            }
        }
        @include xs {
            font-size: 18px;
        }
    }
    .accordion-body {
        padding: 0 50px 30px 0;
        border-bottom: 0;
        p {
            color: $white-color;
            opacity: 0.7;
        }
        @include sm {
            padding: 10px 0 20px;
        }
    } 
    &:has(.accordion-button:not(.collapsed)) {
        background: #202022;
    }
}