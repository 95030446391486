/* Testimonial 1 ---------------------------------- */
.testimonial-thumb1 {
  img {
    border-radius: 20px;
    width: 100%;
  }
}

.testi-card {
  position: relative;
  background: $smoke-color;
  border-radius: 20px;
  padding: 60px;

  @include sm {
    padding: 40px;
  }

  @include xs {
    padding: 30px;
  }

  .quote-icon {
    position: absolute;
    top: 0px;
    right: 40px;
  }

  .rating {
    margin-bottom: 20px;

    i {
      color: #b8bb0a;
      font-size: 20px;

      &:last-child {
        color: $white-color;
      }
    }
  }

  .testi-profile-title {
    font-family: $body-font;
    font-weight: 700;
    font-size: 22.6875px;
    line-height: 29px;
    margin-bottom: 15px;
    margin-top: -0.3em;
  }

  .testi-profile-desig {
    font-family: $body-font;
    font-weight: 400;
    font-size: 18.2812px;
    line-height: 34px;
    color: $body-color;
    font-family: $body-font;
  }

  .testi-card_text {
    font-size: 20px;
    font-weight: 400;
    font-family: $body-font;
    color: $title-color;
    margin-bottom: 40px;
  }

  .testi-card-profile {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;

    @include vxs {
      display: block;
    }
  }

  .rating {
    color: #f8b427;
    display: flex;
    gap: 10px;
  }
}

.testi-profile_thumb {
  min-width: 70px;

  @include vxs {
    margin-bottom: 15px;
  }

  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
}

.testi-desc {
  font-size: 18px;
  margin-left: 70px;
  margin-bottom: 75px;

  @include md {
    margin-left: 0;
  }
}

.testi-area-slider {
  position: relative;
  margin: 130px 0px 0px -240px;

  @media (max-width: 1399px) {
    margin: 70px 0px 0px -240px;
  }

  @include md {
    margin: 0;
  }

  .icon-box {
    gap: 20px;
    position: absolute;
    right: 20px;
    bottom: -15px;
    display: flex;
  }

  .slick-arrow {
    --icon-size: 40px;
    border-radius: 10px;
    color: var(--theme-color);
    border: 1px solid #f3f5f4;
    background: var(--white-color);
    box-shadow: none;
    top: unset;
    bottom: 40px;
    right: -5px;
    opacity: 1;
    visibility: visible;

    &.slick-next {
      top: unset;
      bottom: 90px;
      left: auto;
      right: -5px;
    }
  }
}

.testi-slider-1 {
  position: relative;

  .slick-dots {
    position: absolute;
    right: 74px;
    bottom: 60px;
    display: inline-block;
    width: auto;
  }
}

@include lg {
  .testi-card .testi-card_text {
    font-size: 18px;
  }
}

@include md {
  .testimonial-thumb1 {
    display: none;
  }
}

/* Testimonial 2 ---------------------------------- */
.testimonial-area-2 {
  &.arrow-wrap {
    .slick-arrow {
      background-color: $white-color;
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      top: unset;
      bottom: 15px;
      left: 42.5%;
      border: 1px solid #f3f5f4;
      border-radius: 10px;
      opacity: 1;
      visibility: visible;
      transition: all 0.4s ease-in-out;
      box-shadow: none;

      &:hover {
        background-color: $theme-color;
        color: $white-color;
      }

      &.slick-next {
        margin-top: 20px;
        left: unset;
        right: 28%;
      }
    }
  }
}

.testi-box {
  background-color: $white-color;
  padding: 60px 60px 0 60px;
  position: relative;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  text-align: center;
  margin-left: 110px;

  @include xl {
    margin-left: 30px;
    padding: 30px;
  }
  @include md {
    margin: 40px 0 0 0;
  }

  .quote-icon {
    position: relative;
    width: 55px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    border-radius: 50%;
    background: $theme-color2;
    display: block;
    margin: auto auto 20px auto;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      width: 63px;
      height: 1px;
      background-color: rgba(25, 53, 45, 0.2);
    }

    &:after {
      left: -90px;
    }

    &:before {
      right: -90px;
    }
  }

  &_text {
    font-family: $body-font;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    text-align: center;
    margin-bottom: 30px;

    @include xs {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .testi-profile {
    &-title {
      font-family: $body-font;
      font-weight: 500;
      font-size: 22px;
      line-height: 29px;
      text-align: center;
      color: #243f37;
      margin-bottom: 20px;
    }

    &_thumb {
      min-width: 86px;

      img {
        width: 86px;
        height: 86px;
        object-fit: cover;
        margin-bottom: -30px;

        @include md {
          margin-bottom: 0;
        }
      }
    }
  }
}

/* Testimonial 3 ---------------------------------- */
.testi-area-slider4 {
  margin-right: -80px;
  position: relative;
  @include md {
    margin-right: 0;
  }
  .icon-box {
    position: absolute;
    right: 40px;
    bottom: 40px;
    .slick-arrow {
      background: transparent;
      border-radius: 0;
      width: auto;
      height: auto;
      line-height: normal;
      img {
        filter: brightness(9) grayscale(1);
        transition: 0.4s;
        width: 50px;
      }
      &:hover {
        img {
          filter: none;
        }
      }
    }
    @include vxs {
      display: none;
    }
  }
}
.testi-card.style2 {
  background: $theme-color;
  border-radius: 20px;
  padding: 40px;
  z-index: 1;
  .testi-profile-title {
    font-size: 22px;
    font-weight: 700;
    color: $white-color;
    margin-bottom: 10px;
  }
  .testi-profile-desig {
    font-size: 18px;
    font-weight: 400;
    color: $white-color;
  }
  .testi-card_text {
    font-size: 24px;
    font-weight: 400;
    color: $white-color;
    line-height: 1.583;
    margin-top: 30px;
    margin-bottom: 48px;
    @include xs {
      font-size: 20px;
    }
    @include vxs {
      margin-bottom: 28px;
      font-size: 16px;
    }
  }
  .rating {
    margin-bottom: 0;
  }
  .quote-icon {
    z-index: -1;
    filter: brightness(9);
    width: 295px;
    right: 45px;
    bottom: 55px;
    top: auto;
  }
  @include vxs {
    padding: 30px;
  }
}
/* Testimonial 4 ---------------------------------- */
.testimonial-area-5 {
  .testimonial-thumb1 {
    position: relative;
    z-index: 1;
  }
}
.testi-card.style3 {
  border: 1px solid #243f37;
  background: transparent;
  padding: 60px 123px 60px 60px;
  .quote-icon {
    position: initial;
    margin-bottom: 30px;
  }
  .testi-card_text {
    font-size: 30px;
    color: $white-color;
    line-height: 1.666;
    margin-bottom: 60px;
    @include lg {
      font-size: 20px;
      margin-bottom: 40px;
    }
  }
  .testi-profile-title {
    color: $white-color;
    margin-bottom: 10px;
  }
  .testi-profile-desig {
    color: $white-color;
    opacity: 0.7;
  }
  @include md {
    padding: 60px;
  }
  @include xs {
    padding: 30px;
  }
  @include vxs {
    .testi-card_text {
      font-size: 16px;
    }
  }
}
